@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


*::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

@media screen and (max-width: 2) {
  *::-webkit-scrollbar {
    display: none;
  }
}

*::-webkit-scrollbar-track {
  display: none;
}

*::-webkit-scrollbar-thumb {
  background: rgba(49, 130, 122, 0.3);
  border-radius: 0.8em;
}

*::-webkit-scrollbar-thumb:hover {
  background: rgba(49, 130, 122, 0.76);
}

*::-webkit-scrollbar-track {
  position: absolute;
  top: 0;
}

* {
  text-overflow: ellipsis;
  box-sizing: border-box;
}

@layer utilities {
  .text-shadow-black {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  }
}
